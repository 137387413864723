import { Component, useState, useCallback, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { toast } from 'react-toastify';


class Projects extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      project_admins: [],
      jcr_templates: [],
      invoice_templates: [],    
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getProjects(){
    try {
      const response = await fetch('/api/projects');
      const json = await response.json();
      this.setState({ data: json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  
  async getProjectAdmins(){
    try {
      const response = await fetch('/api/users-by-role/project_admin');
      const json = await response.json();
      this.setState({ project_admins: json.length?json:[] });
    } catch (error) {
      console.log(error);
    }
  }

  async getProjectTemplates(){
    try {
      const response = await fetch('/api/project-templates');
      const json = await response.json();
      this.setState({ 
        jcr_templates: json?.JcrTemplates.length?json.JcrTemplates:[],
        invoice_templates: json?.InvoiceTemplates.length?json.InvoiceTemplates:[] 
      });
    } catch (error) {
      console.log(error);
    }
  }

  add() {
    this.setState({
      id: "",
      project_code: "",
      project_name: "",
      ottc: "",
      ittc: "",
      project_admin: "",
      jcr_template: "",
      invoice_template: "",
      show: true,
      show_content: "form"
    });
  }

  view(Id) {    
    const projects = this.state.data;
    const selectedProject = projects.filter(e=> e.id === Id)[0];
    this.setState(selectedProject);
    this.setState({ show: true, show_content: "view", filename: "" });
  }

  edit(Id) {    
    const projects = this.state.data;
    const selectedProject = projects.filter(e=> e.id === Id)[0];
    this.setState(selectedProject);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  delete(Ids) {        
    deleteItem(`/api/projects?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getProjects();
    });
  }

  componentDidMount() {
    this.getProjects();
    this.getProjectAdmins();
    this.getProjectTemplates();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/project/'+data.id, data)
        .then((response) => {
          console.log(response);
          toast.success(response.message);          
          this.getProjects();
      });
    }else{
      postData('/api/project', data)
        .then((response) => {
          toast.success(response.message);          
          this.getProjects();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;      
      return(
        <>
          {!this.state.show?(
            <>     
              <button className="btn btn-sm btn-primary fw-bold mx-2" onClick={this.add}>
                <FontAwesomeIcon icon={faPlus} /> Add Project
              </button>         
              <ProjectList data={data} view={this.view} edit={this.edit} delete={this.delete} />
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="project-form p-3 bg-white shadow-sm rounded border-0 w-50">
                    <h4 className="mb-3 fw-bold"> {this.state.id?"Edit Project":"New Project"} </h4>
                    <form onSubmit={this.handleSubmit}>
                      <fieldset id="project-from" className="my-0 mx-auto">
                        <div className="row">
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="project_code">Project Code</label>
                            <input type="text" name="project_code" className="form-control shadow-none" maxLength="100" value={this.state.project_code} onChange={this.handleInputChange} required />
                          </div>                          
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="project_name">Project Name</label>
                            <input type="text" name="project_name" className="form-control shadow-none" maxLength="255" value={this.state.project_name} onChange={this.handleInputChange} required />
                          </div>  
                          <div className="col-xs-6 col-md-6 mb-3">
                            <label htmlFor="ottc">Days to complete order</label>
                            <input type="number" name="ottc" className="form-control shadow-none" value={this.state.ottc} onChange={this.handleInputChange} required />
                          </div>                                                    
                          <div className="col-xs-6 col-md-6 mb-3">
                            <label htmlFor="ittc">Days to complete installation</label>
                            <input type="number" name="ittc" className="form-control shadow-none" value={this.state.ittc} onChange={this.handleInputChange} required />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="project_admin">Project Admin</label>
                            <select name="project_admin" className="form-select shadow-none" value={this.state.project_admin} onChange={this.handleInputChange} required>
                              <option value=""> -- Select User --</option>
                              {this.state.project_admins.map((row)=>
                                <option key={"PA"+row.id} value={row.id}>{row.username}</option>
                              )}
                            </select>
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="jcr_template">JCR Template</label>
                            <select name="jcr_template" className="form-select shadow-none" value={this.state.jcr_template} onChange={this.handleInputChange}>
                              <option value=""> -- Select Template --</option>
                              {this.state.jcr_templates.map((row)=>
                                <option key={"PA"+row} value={row}>{row.toLocaleUpperCase()}</option>
                              )}
                            </select>
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="invoice_template">Invoice Template</label>
                            <select name="invoice_template" className="form-select shadow-none" value={this.state.invoice_template} onChange={this.handleInputChange}>
                              <option value=""> -- Select Template --</option>
                              {this.state.invoice_templates.map((row)=>
                                <option key={"PA"+row} value={row}>{row.toLocaleUpperCase()}</option>
                              )}
                            </select>
                          </div>
                        </div>                                                                  
                        <div>
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <ViewProject data={this.state} close={()=> this.setState({show: false})} />
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const ProjectList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const nameMatches = item.project_name && item.project_name.toLowerCase().includes(filterText.toLowerCase());
    const codeMatches = item.vendor_name && item.vendor_name.toLowerCase().includes(filterText.toLowerCase());    

    return nameMatches || codeMatches
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Projects_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none w-25" 
           placeholder="Search by project code, name" 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Projects</h4>       
      </>
    );
  }

  const columns = [     
      {
        name: 'Project Code',
        selector: row => row.project_code,
        sortable: true,
      },          
      {
        name: 'Project Name',
        selector: row => row.project_name,
        sortable: true,
      },
      {
        name: 'Days to complete order',
        selector: row => row.ottc,
        sortable: true,
      },
      {
        name: 'Days to complete installation',
        selector: row => row.ittc,
        sortable: true,
      },
      {
        name: 'Project Admin',
        selector: row => row.project_incharge,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row => row.edit,
      }
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,      
      project_code: item.project_code,
      project_name: item.project_name,
      ottc: item.ottc,
      ittc: item.ottc,
      project_incharge: item.project_admin_name,
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" onClick={()=> props.edit(item.id)} />
    }
  });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.project_name)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
             //remove this if you dont need row hover
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}                 
          />
        </div>
      </div>
    </>
  );
}

const ViewProject = (props)=> {
  return (
    <div className="p-3 bg-light shadow-sm rounded">
      <h4 className="mb-3 fw-bold"> {props.data.title} </h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <p className="mb-1">Project Code</p>
          <h6>{props.data.project_code}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Project Name</p>
          <h6>{props.data.project_name}</h6>
        </div>               
        <div>
          <button className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
        </div>
      </div>
    </div>
  );
}

export { Projects };
