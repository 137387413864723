import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose, faFileLines } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button } from 'react-bootstrap';
const XLSX = require("xlsx");


function AgeGroupOrders(props) {
    const navigate = useNavigate();
    const { projectId, ageGroup } = useParams();
    const [orders, setOrders] = useState([]);
    const [data, setData] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);        
    const [filters, setFilters] = useState({
      project: "",
      status: "",
      searchText: ""
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);  
  
    const handleFilterChange = (e)=> {
      const name = e.target.name;
      const value = e.target.value;
      setFilters((prevValues)=> ({
        ...prevValues,
        [name] : value
      }));
      let filteredItems = [];
  
      if(name==="searchText"){
        filteredItems = orders.filter(item => {
          const farmerMatches  = item.farmer_name && item.farmer_name.toLowerCase().includes(value.toLowerCase());
          const primaryNoMatches  = item.primary_no && item.primary_no.toLowerCase().includes(value.toLowerCase());
          return farmerMatches || primaryNoMatches;
        });
      }else{
        filteredItems = orders.filter(item => item[name] == (value?value:item[name]));
      }
  
      setData(filteredItems);
    }
  
    const actionComponentMemo = useMemo(() => {
      const handleClear = () => {
        if (filters.serachText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilters((prevValues)=> ({
            ...prevValues,
            "searchText" : ""
          }));
        }
      };
  
      const XLSXDownload = () => {
        var worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        const filename = `OMS_Age_${ageGroup}_Orders_${moment().format('YYYYMMDD')}`;
        XLSX.utils.book_append_sheet(workbook, worksheet, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
  
      return (
        <div className="d-flex align-items-center">
          <div className="input-group input-group-sm me-2">                   
            <select name="application_status" className="form-select shadow-none" value={filters.application_status} onChange={handleFilterChange} style={{maxWidth: '220px'}}>
              <option value="">-- Status filter --</option>
              {
                orderStatuses.map((o,i)=>
                  <option key={o.status+i} value={o.activity}>{o.activity}</option> 
                )
              }
            </select>
            <input
              name="searchText"
              type="text" 
              className="form-control shadow-none" 
              placeholder="Search by farmer name, saral no ..." 
              aria-label="Search area"          
              value={filters.serachText} 
              onChange={handleFilterChange}          
            />        
            <button className="btn btn-light border" type="button" onClick={handleClear}>
              <FontAwesomeIcon icon={faClose} />
            </button>          
          </div>
          <div>
            <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
              <FontAwesomeIcon className="pe-2" icon={faFileLines} /> Report
            </button>
          </div>
        </div>
      );
    }, [filters, resetPaginationToggle, orderStatuses,]);
  
    const columns = [
        {
            name: 'Order Primary No.',
            selector: row => row.primary_no,
            sortable: true,         
        },
        {
            name: 'Order Date',
            selector: row => row.order_date,
            sortable: true,          
        },
        {
            name: 'Project Code',
            selector: row => row.project_code,
            sortable: true,          
        },
        {
            name: 'Farmer Name',
            selector: row => row.farmer_name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.application_status,
            sortable: true,
        },
        {
            name: 'Motor Type',
            selector: row => row.pump_type,
            sortable: true,
        },
        {
            name: 'Pump Sub Type',
            selector: row => row.pump_sub_type,
            sortable: true,
        },
        {
            name: 'Pump Capacity',
            selector: row => row.pump_capacity,
        }
    ]; 
  
    const tableData = data.map((item, index)=>{
      return {
        id            : item.id,
        primary_no    : item.primary_no,
        order_date    : moment(item.order_date).format("DD/MM/YYYY HH:MM"),
        project_code  : item.project_code,
        farmer_name   : item.farmer_name,
        application_status : item.application_status,
        pump_type: item.pump_type,
        pump_sub_type : item.pump_sub_type,
        pump_capacity : item.pump_capacity,
      }
    });
  
    const THeader = ()=> {
      let TitleStr = "";
      switch(ageGroup) {
        case "cp_gt_60":
          TitleStr = "Orders time to complete > 60 days";
          break;
        case "cp_gt_30":
          TitleStr = "Orders time to complete > 30 days";
          break;
        case "cp_lt_15":
          TitleStr = "Orders time to complete < 15 days";
          break;
        case "ov_lt_15":
          TitleStr = "Orders time to complete Overdue < 15 days";
          break;
        case "ov_gt_30":
          TitleStr = "Orders time to complete Overdue > 30 days";
          break;
        default:
          TitleStr = "Orders time to complete";
          break;
      }

      return(
        <>
          <h4 className="mb-0">{TitleStr}</h4>       
        </>
      );
    }

    const getAgeGroupOrders = async()=> {       
      try{
        const response = await fetch(`/api/orders-by-age?projectId=${projectId}&ageGroup=${ageGroup}`);
        const json = await response.json();
        setOrders(json.length?json:[]);
        setData(json.length?json:[]);
      }catch(e){
        toast.error(e)
      } 
    }
  
    const getOrderStatuses = async()=> {
      try{
        const response = await fetch("/api/order-activities");
        const json = await response.json();
        setOrderStatuses(json.length?json:[]);
      }catch(e){
        toast.error(e)
      }
    }
  
    useEffect(()=>{        
      getAgeGroupOrders();
      getOrderStatuses();
    },[projectId])
  
    
    return(
      <>
        <Button variant="outline-secondary mx-2" onClick={()=> navigate("/")}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Dashboard
        </Button>
        <div id="order-list" className="mb-2 block p-2">
          <div className="shadow">
            <DataTable
              title={<THeader />}                        
              columns={columns}
              data={tableData}
              actions={actionComponentMemo}
              customStyles={customTableStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="90vh" //remove this if you dont need table scroll
              selectableRowsHighlight
              pointerOnHover
              className=" border z-10"                            
              onRowClicked={()=> console.log("Go to order details page")}
            />
          </div>
        </div>
      </>
    );  
}

export { AgeGroupOrders }