import { Component, useState, useCallback, useEffect, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil, faPlus, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { Tab, Tabs, ListGroup, Row, Col, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';

const userObj = {
  username: '',
  email: '',
  isSuperAdmin: false
};

class Users extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      roles: [],
      organisations: [],
      currentUser: userObj,
      activeKey : "project_admin",
      isLoading : true,
      show : false,
      show_content : ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.setActiveKey = this.setActiveKey.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }  

  async getUsers(){
    try {
      const response = await fetch('/api/users');
      const json = await response.json();
      this.setState({ data:json.users, roles:json.roles });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async validateCurrentUser(){
    try {
      const response = await fetch('/api/validate-current-user');
      const json = await response.json();
      this.setState({ currentUser:json.length?json[0]: userObj});
    } catch (error) {
      console.log(error);
    }
  }

  async getOrganisations(){
    try {
      const response = await fetch('/api/organisations');
      const json = await response.json();
      this.setState({ organisations: json.length?json:[] });
    } catch (error) {
      console.log(error);
    }
  }

  setActiveKey(Key){
    this.setState({ activeKey: Key, show: false });
  }

  add() {
    const selectedRole = this.state.roles.filter(r=> r.role_name===this.state.activeKey)[0];
    this.setState({
      id: "",
      username: "",
      email: "",      
      password: "",      
      role_id: selectedRole.id,
      user_role: selectedRole.role_name,
      organisation_id: "",
      phone: "",
      is_active: 1,
      show: true,
      show_content: "form"
    });
  }

  view(Id) {    
    const users = this.state.data;
    const selectedUser = users.filter(e=> e.id === Id)[0];
    this.setState(selectedUser);
    this.setState({ show: true, show_content: "view" });
  }

  edit(Id) {    
    const users = this.state.data;
    const selectedUser = users.filter(e=> e.id === Id)[0];
    this.setState(selectedUser);
    this.setState({ show: true, show_content: "form" });
  }

  delete(Id) {        
    deleteItem(`/api/user/${Id}`)
    .then((response) => {
      toast.success(response.message);
      this.getUsers();
    });
  }

  componentDidMount() {
    this.getOrganisations();
    this.validateCurrentUser();
    this.getUsers();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/user/'+data.id, data)
        .then((response) => {
          toast.success(response.message);
          this.getUsers();
      });
    }else{
      postData('/api/user', data)
        .then((response) => {
          toast.success(response.message);
          this.getUsers();
      });
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data; 
      return(
        <>                 
          {/* <UserList data={data}/> */}
          <div className="row">               
            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="card mt-1 shadow">
              {this.state.currentUser.isSuperAdmin &&
                <Tabs activeKey={this.state.activeKey} onSelect={(k) => this.setActiveKey(k)} id="user-tabs" className="bg-light border-none" fill>
                  <Tab eventKey="super_admin" tabClassName="rounded-0" title="Super Admin">
                    <UserList category="super_admin" data={this.state.data} view={this.view} add={this.add} edit={this.edit} delete={this.delete}  />
                  </Tab>
                  <Tab eventKey="project_admin" tabClassName="rounded-0" title="Project Admin">
                    <UserList category="project_admin" data={this.state.data} view={this.view} add={this.add} edit={this.edit} delete={this.delete}  />
                  </Tab>                                                                    
                </Tabs> 
              }               
              </div>
            </div>
            {this.state.show &&
              <div className="col-xs-12 col-md-6 col-lg-6">
                {this.state.show_content==="form"?(
                  <>
                    <div className="user-form p-3 mt-1 bg-white shadow-sm rounded border-0 float-end w-100">
                      <h5 className="mb-3 px-3 fw-bold text-secondary"> {this.state.id?"Edit User details":"New User"} </h5>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="user-from" className="my-0 mx-auto px-3 overflow-y-scroll" style={{height: '78vh'}}>
                          <div className="row">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="username">Full Name</label>
                              <input type="text" name="username" className="form-control shadow-none" maxLength="100" value={this.state.username} onChange={this.handleInputChange} required />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="email">Email</label>
                              <input type="email" name="email" className="form-control shadow-none" maxLength="255" value={this.state.email} onChange={this.handleInputChange} required />
                            </div>
                            {!this.state.id &&
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="password">Password</label>
                              <input type="text" name="password" className="form-control shadow-none" maxLength="16" value={this.state.password} onChange={this.handleInputChange} required />
                            </div>
                            }
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="phone">Phone</label>
                              <input type="text" name="phone" className="form-control shadow-none" maxLength="20" value={this.state.phone} onChange={this.handleInputChange} />
                            </div>
      
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="user_role">Role</label>
                                <input type="text" className="form-control shadow-none bg-light text-capitalize" value={this.state.user_role?this.state.user_role.replaceAll("_"," "):""} readOnly />  
                            </div>                            
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="organisation_id">Organisation</label>
                              <select name="organisation_id" className="form-select shadow-none" value={this.state.organisation_id} onChange={this.handleInputChange} required>
                                <option value=""> -- Select Organisation -- </option>
                                {((this.state.user_role==="project_admin") || (this.state.user_role==="super_admin"))?(
                                  this.state.organisations.filter(e=> e.org_type==="admin").map(o=>
                                    <option key={o.id} value={o.id}>{o.org_name}</option>
                                  )
                                ):(
                                  this.state.organisations.filter(e=> e.org_type===this.state.user_role).map(o=>
                                    <option key={o.id} value={o.id}>{o.org_name}</option>
                                  )
                               )}                                                                
                              </select>
                            </div>

                            <div className="col-xs-12 col-md-12 mb-2">
                              <label htmlFor="title">Status</label>
                              <select name="is_active" className="form-select shadow-none" value={this.state.is_active} onChange={this.handleInputChange} required>
                                <option value="0"> Inactive </option>
                                <option value="1"> Active </option>
                              </select>
                            </div>
                          </div>                        
                          <div className="mt-3">
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </>
                ):(
                  <ViewUser data={this.state} view={this.view} edit={this.edit} delete={this.delete} close={()=> this.setState({show: false})} />
                )}
              </div>
            }                
          </div>
        </>               
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const ViewUser = (props)=> {
  return (
    <div className="p-3 mt-1 bg-white shadow-sm" style={{height: '84vh'}}>
      <h5 className="mb-3 fw-semibold text-secondary"> User Information </h5>
      <div className="row border-bottom">
        <div className="col p-3"> 
          <div className="d-flex align-items-center">
            <div className="pe-3">
              <Image src="img/user_placeholder.jpeg" width="150px" height="auto" roundedCircle />
            </div>
            <button type="button" className="btn btn-sm btn-light border">
              <FontAwesomeIcon icon={faPencil} /> &nbsp; Change profile picture
            </button>
          </div>
        </div>
      </div>
      <div className="row">    
        <div className="col-md-12 py-2">
          <h5 className="my-3 text-secondary">{props.data.username}</h5>  
          <h6 className="my-3 text-secondary text-capitalize">{props.data.user_role}</h6>
          <h6 className="my-3 text-secondary">{props.data.organisation}</h6>
          <h6 className="my-3">
            <a href={`mailto:${props.data.email}`} className="text-secondary fst-italic">{props.data.email}</a>
          </h6>
          <h6 className={props.data.is_active?"text-success":"text-danger"}>{props.data.is_active?"Active":"Inactive"}</h6>
          <div className="my-3">
            <button type="button" className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

const UserList = (props)=> {
  const [filterText, setFilterText] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [toggleCleared, setToggleCleared] = useState(false);
  
  const filteredItems = data.filter(item => {
    const nameMatches = item.username && item.username.toLowerCase().includes(filterText.toLowerCase());
    const emailMatches = item.email && item.email.toLowerCase().includes(filterText.toLowerCase());
    const roleMatches = item.user_role && item.user_role.toLowerCase().includes(filterText.toLowerCase());

    return nameMatches || emailMatches || roleMatches
  });

  const handleClear = () => {
    if (filterText) {      
      setFilterText('');
    }
  };    

  const getCategorizedData = async()=> {    
    const filteredData = props.data.filter(e=> e.user_role===props.category);
    await setData(filteredData);
    setLoading(false);
  }

  const handleDelete = (sItem) => {
    confirmAlert({
      title: 'Delete confirmation',
      message: `Are you sure you want to delete:\r ${sItem.username}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {            
            props.delete(sItem.id);
            setToggleCleared(!toggleCleared);
          }
        },
        {
          label: 'No',
          onClick: () => console.log("Delete operation cancelled.")
        }
      ]
    });
    // setData(differenceBy(data, selectedRows, 'title'));      
  };

  useEffect(()=>{
    getCategorizedData();
  },[props.category]);

  
  return(
    <>
      {!isLoading?(
        <div className="block p-2 mb-2">
          <button className="btn btn-sm btn-primary fw-bold mx-2 text-capitalize" onClick={()=> props.add(props.category)}>
            <FontAwesomeIcon icon={faPlus} /> Add {props.category?props.category.replaceAll("_"," "):""} User
          </button>           
          <div className="d-flex justify-content-start align-items-center my-2">
            <div className="input-group input-group-sm mx-2">
              <input
               type="text" 
               className="form-control shadow-none" 
               placeholder="Search by name ..." 
               aria-label="Search area"          
               value={filterText} 
               onChange={e => setFilterText(e.target.value)}          
              />        
              <button className="btn btn-light border" type="button" onClick={handleClear}>
                <FontAwesomeIcon icon={faClose} />
              </button>          
            </div>            
          </div>      
          <ListGroup variant="flush" className="overflow-y-scroll" style={{height: '67vh'}}>
            {filteredItems.map(row=>
              <ListGroup.Item key={row.id}>
                <Row xs={12} className="align-items-center">
                  <Col xs={12} md={3} className="action-icons" onClick={()=> props.view(row.id)}>
                    <Image src="img/user_placeholder.jpeg" width="60px" height="auto" roundedCircle />
                  </Col>
                  <Col xs={12} md={8} className="action-icons" onClick={()=> props.view(row.id)}>
                    <h6 className="mb-0 mt-1">{row.username}</h6>
                    <small className="text-secondary">{row.email}</small>
                    <br />
                    <small className="text-secondary fw-bold text-capitalize">{row.user_role?row.user_role.replaceAll("_"," "):""}</small>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <small className={row.is_active?"text-primary":"text-danger fw-semibold"}>{row.is_active?"Active":"Inactive"}</small>
                  </Col>
                  <Col xs={12} md={1}>
                    <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={()=> props.edit(row.id)} />
                  </Col>                
                </Row>
              </ListGroup.Item>          
            )}        
          </ListGroup>          
        </div>
      ):(
        <></>
      )}
    </>
  );
}

export { Users };
