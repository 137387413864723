import { Component, useState, useCallback, useMemo, differenceBy } from 'react';
import { postData, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil, faPlus, faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { Container, Row, Col, Card, Form, ListGroup, Button, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ProductCategories } from '../utils/faker';
import { Modal } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';


const bosItemObj = { 
  product_category: "", 
  products: [],
  units: "",   
};

const invItemObj = { 
  item_name     : "", 
  tax_percentage: "",
  net_value     : "",
  total_value   : "" 
};

class BOS extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      projects: [],
      products : [],
      lineItems: Array(14).fill(bosItemObj),
      invoiceItems: Array(13).fill(invItemObj),
      isLoading : true,
      show : false,
      show_content : "",
      activeTab: "lineItems",
      showModal: false,
      selectedCategory: "",
      selectedLineItem: "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLineItemsChange = this.handleLineItemsChange.bind(this);
    this.handleInvoiceItemsChange = this.handleInvoiceItemsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);

  }

  async getProjects(){
    try {
      const response = await fetch('api/projects');
      const json = await response.json();
      this.setState({ projects:json });
    } catch (error) {
      console.error(error);
    }
  } 

  async getBoss(){
    try {
      const response = await fetch('/api/boss');
      const json = await response.json();
      this.setState({ data: json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getProducts(){
    try {
      const response = await fetch('/api/products');
      const json = await response.json();
      this.setState({ products: json.length?json:[] });
    } catch (error) {
      console.log(error);
    }
  }

  async getBOSItems(bosId){
    try {
      const response = await fetch(`/api/bos-items/${bosId}`);
      const json = await response.json();      
      const updatedItems = this.state.lineItems.map((item, index) => (index < json.length ? { 'product_category': json[index].product_category, 'units': json[index].units, 'products': json[index].products } : bosItemObj));
      this.setState({ lineItems: updatedItems});  
    } catch (error) {
      console.log(error);
    }
  }

  async getBOSInvoiceItems(bosId){    
    try {
      const response = await fetch(`/api/bos-invoice-items/${bosId}`);
      const json = await response.json();
      const updatedItems = this.state.invoiceItems.map((item, index) => (index < json.length ? { 'item_name': json[index].item_name, 'tax_percentage': json[index].tax_percentage, 'net_value': json[index].net_value, 'total_value': json[index].total_value, } : invItemObj));
      this.setState({ invoiceItems : updatedItems });
    } catch (error) {
      console.log(error);  
    }
  }

  add() {
    this.setState({
      id: "",
      project_code: "",
      bos_name: "",
      pump_type: "",
      pump_capacity: "",
      pump_sub_type: "",
      head_length: "",      
      lineItems: Array(14).fill(bosItemObj),
      invoiceItems: Array(13).fill(invItemObj),    
      show: true,
      show_content: "form",
      activeTab: "lineItems"
    });
  }

  view(Id) {    
    const boss = this.state.data;
    const selectedBos = boss.filter(e=> e.id === Id)[0];
    this.setState(selectedBos);
    this.setState({ show: true, show_content: "view", filename: "" });
  }

  edit(Id) {    
    const boss = this.state.data;
    const selectedBos = boss.filter(e=> e.id === Id)[0];
    this.setState(selectedBos);    
    this.getBOSItems(Id);
    this.getBOSInvoiceItems(Id);
    this.setState({ show: true, show_content: "form", filename: "", activeTab: "lineItems" });
  }

  delete(Ids) {        
    deleteItem(`/api/bos?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getBoss();
    });
  }

  componentDidMount() {
    this.getBoss();
    this.getProducts();
    this.getProjects();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleLineItemsChange(e){
    const name = e.target.name;
    const value = e.target.value;
    const index = e.target.attributes.dataindex.value;        
    
    const updatedLineItems = [...this.state.lineItems];
    if(name==="product_category" && value){
      const existingItem = this.state.lineItems.filter(e=> e.product_category===value);
      if(existingItem.length){
          toast.warning("Item is already in the list.")
      }else{
        updatedLineItems[index] = {
          [name]   : value,
          units    : this.state.lineItems[index].units,
          products : []
        };        
      }              
    }else{
      updatedLineItems[index] = {
        ...this.state.lineItems[index],
        [name] : value
      };      
    }
    this.setState({
      lineItems: updatedLineItems
    }); 
  }

  handleInvoiceItemsChange(e){
    const name = e.target.name;
    const value = e.target.value;
    const index = e.target.attributes.dataindex.value;
    let netVal = "";
    let gstPercent = "";
    let taxVal = "";
    const updatedInvoiceItems = [...this.state.invoiceItems];
    console.log(value)
    if(name==="tax_percentage"){
      netVal = parseInt(updatedInvoiceItems[index].net_value)?parseInt(updatedInvoiceItems[index].net_value):0;
      gstPercent = parseInt(value)?parseInt(value):0;
      taxVal = gstPercent/100*netVal;
    }else if(name==="net_value"){
      netVal = parseInt(value)?parseInt(value):0;
      gstPercent = parseInt(updatedInvoiceItems[index].tax_percentage)?parseInt(updatedInvoiceItems[index].tax_percentage):0;
      taxVal = gstPercent/100*netVal;
    }
    updatedInvoiceItems[index] = {
      ...this.state.invoiceItems[index],
      [name]      : value,
      total_value : taxVal+netVal
    };          
    this.setState({
      invoiceItems: updatedInvoiceItems
    }); 
  }

  handleSubmit(e){
    e.preventDefault();    
    var bosData = this.state;
    // delete bosData.data;
    // delete bosData.products;   
    const lineItemsCount = this.state.lineItems.filter(e=> e.product_id);
    const invoiceItemsCount = this.state.invoiceItems.filter(e=> e.item_name && e.net_value);
    if(lineItemsCount.length || invoiceItemsCount.length){
      this.setState({ isLoading: true });
      if(bosData.id){
        updateData('/api/bos/'+bosData.id, bosData)
          .then((response) => {
            toast.info(response.message);
            this.setState({show: false});          
            this.getBoss();
        }).catch(e=> toast.error(e));
      }else{
        postData('/api/bos', bosData)
          .then((response) => {
            toast.success(response.message);
            this.setState({show: false});          
            this.getBoss();
        }).catch(e=> toast.error(e));
      }
    }else{
      toast.warning("Must have atleast 1 Product line item or Invoice line item");
      this.setState({activeTab: "lineItems"});
    }    
  }

  handleModalShow = (index) => {    
    this.setState({ showModal: true, selectedLineItem: index, selectedCategory: this.state.lineItems[index].product_category });
  }
  
  // Add this method to your component
  handleModalClose = () => {
    this.setState({ showModal: false });
  };  

  handleProductChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    console.log(selectedOptions);
    // Assuming you have a selectedLineItem, update its products    
    if (this.state.selectedLineItem !== null) {
      const updatedLineItems = [...this.state.lineItems];
      const lineItem = updatedLineItems[this.state.selectedLineItem];
      lineItem.products = [];
      selectedOptions.forEach((value) => {
        const productObj = this.state.products.find((item) => item.id == value);
        if (productObj) {
          lineItem.products.push({
            product_id: productObj.id,
            product_name: productObj.product_name,
            category: productObj.category,
          });
        }
      });
      this.setState({
        lineItems: updatedLineItems,
      });
    }  
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;      

      return(
        <>
          {!this.state.show?(
            <>     
              <button className="btn btn-sm btn-primary fw-bold mx-2" onClick={this.add}>
                <FontAwesomeIcon icon={faPlus} /> Add Bill Of Supply
              </button>         
              <BosList data={data} view={this.view} edit={this.edit} delete={this.delete} />
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="bos-form p-3 bg-white shadow-sm rounded border-0 h-100">
                    <Modal size="lg" show={this.state.showModal} onHide={this.handleModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Select <span className="text-uppercase">{this.state.selectedCategory}</span> Products
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="h-50">
                          <Col>
                            <Form.Select 
                              multiple 
                              onChange={this.handleProductChange} 
                              selected={this.state.selectedLineItem ? this.state.lineItems[this.state.selectedLineItem].products.map(item=> item.id):[]}
                              className="shadow-none" 
                              style={{height: '50vh'}}>
                              {this.state.products.filter(p=>p.category===this.state.selectedCategory).map((product, index) => (
                                <option key={index} value={product.id}>{product.product_name}</option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>                  
                    <div className="row h-100">
                      <div className="col-xs-12 col-md-4">
                        <form onSubmit={this.handleSubmit} autoComplete="off">
                          <fieldset id="bos-from" className="my-0 mx-auto overflow-y-scroll" style={{height: '85vh'}}>
                            <h4 className="mb-3 fw-bold"> Bill of Supply </h4>
                            <div className="px-2">
                              <div className="mb-3">
                                <label htmlFor="bos_name">Name</label>                                
                                <textarea name="bos_name" className="form-control shadow-none" rows={2} maxLength="100" value={this.state.bos_name} onChange={this.handleInputChange} required />
                              </div>
                              <div className="mb-3">                          
                                <label htmlFor="title">Project</label>
                                <select name="project_code" className="form-select" value={this.state.project_code} onChange={this.handleInputChange}> 
                                  <option value=""> -- Select Project --</option>
                                  {
                                    this.state.projects.map(p=>
                                      <option key={"PJ"+p.id} value={p.project_code}> {p.project_code} - {p.project_name} </option>
                                    )
                                  }
                                </select>
                              </div>                             
                              <div className="mb-3">
                                <label htmlFor="pump_type">Pump Type</label>
                                <select name="pump_type" className="form-select shadow-none" value={this.state.pump_type} onChange={this.handleInputChange} required>
                                  <option value="">-- None --</option>
                                  <option value="AC"> AC </option>
                                  <option value="DC"> DC </option>
                                </select>
                              </div>
                              <div className="mb-3">
                                <label htmlFor="pump_sub_type">Pump Sub Type</label>
                                <select name="pump_sub_type" className="form-select shadow-none" value={this.state.pump_sub_type} onChange={this.handleInputChange} required>
                                  <option value="">-- None --</option>                                  
                                  <option value="Surface"> Surface </option>
                                  <option value="Submersible"> Submersible </option>
                                </select>
                              </div>
                               <div className="mb-3">
                                <label htmlFor="pump_capacity">Pump Capacity</label>
                                <select name="pump_capacity" className="form-select shadow-none" value={this.state.pump_capacity} onChange={this.handleInputChange} required>
                                  <option value="">-- None --</option>
                                  <option value={1}> 1 HP</option>
                                  <option value={2}> 2 HP</option>
                                  <option value={3}> 3 HP</option>
                                  <option value={5}> 5 HP</option>
                                  <option value={7.5}> 7.5 HP</option>
                                  <option value={10}> 10 HP</option>
                                </select>
                              </div>                              
                              <div className="mb-3">
                                <label htmlFor="head_length">Head Length</label>
                                <select name="head_length" className="form-select shadow-none" value={this.state.head_length} onChange={this.handleInputChange} required>
                                  <option value="">-- None --</option>
                                  <option value={10}> 10 MTS</option>
                                  <option value={20}> 20 MTS</option>
                                  <option value={30}> 30 MTS</option>
                                  <option value={50}> 50 MTS</option>
                                  <option value={70}> 70 MTS</option>
                                  <option value={100}> 100 MTS</option>
                                </select>
                              </div>   
                            </div>
                            <div className="mt-2">
                              <Button type="submit" variant="primary" className="m-2">Save</Button>
                              <Button type="button" variant="outline-danger" className="m-2">Delete</Button>
                              <Button type="button" variant="outline-secondary" className="m-2" onClick={()=> this.setState({show: false})}>Cancel</Button>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                      <div className="col-xs-12 col-md-8">
                        <Tabs
                          id="bos-tab"
                          justify
                          activeKey={this.state.activeTab}
                          onSelect={(k) => this.setState({"activeTab": k})}
                          className="mb-3 fw-semibold"
                        >
                          <Tab className="rounded-0" eventKey="lineItems" title="Line Items">
                            <>
                            <ListGroup variant="flush pt-1 overflow-y-scroll" style={{height: '75vh'}}>
                                <ListGroup.Item className="bg-secondary text-light">
                                  <Row xs={12} md={12}>
                                    <Col xs={4} md={3} className="">Category</Col>
                                    <Col xs={4} md={7} className="">Products</Col>
                                    <Col xs={4} md={2} className="text-end">Quantity</Col>
                                  </Row>
                                </ListGroup.Item>
                                {this.state.lineItems.map((row, index) => (
                                  <ListGroup.Item key={"PLI" + index} className="p-0">
                                    <Row xs={12} md={12} className="g-0">
                                      <Col xs={2} md={2}>
                                        <Form.Select name="product_category" value={row.product_category} onChange={this.handleLineItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0">
                                          <option value=""></option>
                                          {ProductCategories.map(cat =>
                                            <option value={cat.id}>{cat.name}</option>
                                          )}
                                        </Form.Select>
                                      </Col>
                                      <Col xs={8} md={8}>
                                          {
                                            row.products.length ? 
                                            row.products.map((product, index) => 
                                              <div key={index}>{product.product_name}</div>
                                            ) 
                                            : null
                                          }
                                      </Col>
                                      <Col xs={1} md={1}>                                  
                                        <Button className="btn btn-sm" variant="outline-primary rounded-0 " onClick={()=>this.handleModalShow(index)}>
                                            <FontAwesomeIcon icon={faPenToSquare} className="fw-bold" />
                                          </Button>
                                      </Col>
                                      <Col xs={1} md={1}>
                                        <Form.Control type="number" name="units" value={row.units} onChange={this.handleLineItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0 text-end" />
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          </Tab>
                          <Tab className="rounded-0" eventKey="invoiceItems" title="Invoice Items">
                            <>
                              <ListGroup variant="flush pt-1">
                                <ListGroup.Item className="bg-secondary text-light">
                                  <Row xs={12} md={12}>
                                    <Col xs={6} md={6} className="">Item Name</Col>
                                    <Col xs={2} md={2} className="text-end">GST %</Col>
                                    <Col xs={2} md={2} className="text-end">Net Rate</Col>
                                    <Col xs={2} md={2} className="text-end">Toatal Value</Col>
                                  </Row>
                                </ListGroup.Item>
                                {this.state.invoiceItems.map((row, index) => (
                                  <ListGroup.Item key={"ILI"+index} className="p-0">
                                    <Row xs={12} md={12} className="g-0">
                                      <Col xs={6} md={6}>
                                        <Form.Control type="text" name="item_name" value={row.item_name} onChange={this.handleInvoiceItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0" />
                                      </Col>
                                      <Col xs={2} md={2} className="text-end">
                                        <Form.Select name="tax_percentage" value={row.tax_percentage} onChange={this.handleInvoiceItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0 text-end">
                                          <option value=""></option>
                                          <option value="12">12 %</option>
                                          <option value="18">18 %</option>
                                        </Form.Select>
                                      </Col>
                                      <Col xs={2} md={2} className="text-end">
                                        <Form.Control type="number" name="net_value" minvalue={0} value={row.net_value} onChange={this.handleInvoiceItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0 text-end" />
                                      </Col>
                                      <Col xs={2} md={2} className="text-end">
                                        <Form.Control type="text" name="total_value" minvalue={0} value={row.total_value} dataindex={index} size="sm" className="shadow-none rounded-0 text-end bg-light" readOnly />
                                      </Col>                                           
                                    </Row>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>                               
                          </Tab>                              
                        </Tabs>                                                      
                      </div>                          
                    </div>                     
                    <datalist id="product-list">
                      {this.state.products.map(row=> 
                        <option key={"PI"+row.id} value={row.product_category} />
                      )}
                    </datalist>
                  </div>
                </>
              ):(
                <ViewBos data={this.state} close={()=> this.setState({show: false})} />
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const BosList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const nameMatches = item.bos_name && item.bos_name.toLowerCase().includes(filterText.toLowerCase());    
    return nameMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_BOS_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by name, vendor ..." 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Bill Of Supply</h4>       
      </>
    );
  }

  const columns = [            
      {
          name: 'Bill Of Supply Name',
          selector: row => row.bos_name,
          sortable: true,
      },
      {
        name: 'Project Code',
        selector: row => row.project_code,
        sortable: true,
      },
      {
          name: 'Total Value',
          selector: row => row.total_value?Math.round(row.total_value):0,
          sortable: true,
      },
      {
          name: 'Action',
          selector: row => row.edit,
      }
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,      
      bos_name: item.bos_name,
      project_code: item.project_code,               
      total_value: item.total_value,
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" onClick={()=> props.edit(item.id)} />
    }
  });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.bos_name)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
             //remove this if you dont need row hover
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}                 
          />
        </div>
      </div>
    </>
  );
}

const ViewBos = (props)=> {
  return (
    <div className="p-3 bg-light shadow-sm rounded">
      <h4 className="mb-3 fw-bold"> {props.data.title} </h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <p className="mb-1">Bill Of Supply Name</p>
          <h6>{props.data.bos_name}</h6>
        </div>                    
        <div>
          <button className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
        </div>
      </div>
    </div>
  );
}

export { BOS };
