import { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { postData, postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { OffCanvas } from '../partials/layouts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faLocationDot, faFile, faClose, faTableList, faTimeline, faChevronLeft, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faUser, faEnvelope, faFileExcel, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { orderActivities } from '../utils/faker';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import ImportFile from '../downloads/site_survey_import.xlsx';
import { toast } from 'react-toastify';
const XLSX = require("xlsx");
const moment = require('moment');


class SiteSurvey extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      selectedOrder : {},      
      isLoading : true,
      show : false,
      show_content : "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getSiteSurveys(){
    try {
      const response = await fetch('/api/site-surveys');
      const json = await response.json();
      console.log(json);
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }  

  add() {
    this.setState({
      id: "",      
      show: true,
      show_content: "upload"
    });
  } 

  edit(Id) {    
    const orders = this.state.data;
    const selectedOrder = orders.filter(e=> e.order_id === Id)[0];
    this.setState(selectedOrder);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  view(row, event) {
    const selectedOrder = this.state.data.filter(e=> e.id === row.id)[0];
    this.setState({show: true, show_content: "", selectedOrder: selectedOrder});
  }

  delete(Ids) {    
    deleteItem(`/api/site-surveys?docRef=[${Ids}]`)
    .then((response) => {      
      toast.info(response.message);
      this.getSiteSurveys();
    });
  }

  componentDidMount() {
    this.getSiteSurveys();
  }
  
  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.order_id){
      const data = this.state;
      updateData(`/api/site-survey/${this.state.order_id}`, data)
      .then((response) => {              
        toast.success(response.message);       
        this.setState({show: false});
        this.getSiteSurveys();
      });
    }else{      
      var formData = new FormData();
      var file = document.getElementById("site-survey-sheet").files[0];      
      if(file){
        var ORfileds = document.getElementById("site-survey-fields");
        ORfileds.disabled=true;
        formData.append('File', file);
        console.log("formData: ", formData);
        postFile('/api/site-survey-upload', formData)
        .then((response) => {          
          toast.success(response.message);
          ORfileds.disabled=false;        
          document.getElementById("site-survey-form").reset();
          this.setState({show: false, show_content:""});
          this.getSiteSurveys();
        });
      }else{
        alert("Select the file first");
      }         
    }    
  } 

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;     
      return(
        <>
          {!this.state.show?(
            <>              
              <div className="d-flex">         
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Site Survey
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>                
              <SiteSurveyList data={data} edit={this.edit} delete={this.delete} view={this.view} />
            </>
          ):(
            <>
              {this.state.show_content==="upload"?(
                <>
                  <div className="card site-survey-form p-3 bg-white shadow-sm rounded w-50 border-0">
                    <form id="site-survey-form" onSubmit={this.handleSubmit}>
                      <fieldset id="site-survey-fields" className="my-0 mx-auto"> 
                        <div className="mb-3">
                          <p className="text-secondary fw-light">Upload your site survey data sheet here. ( .xlsx / .csv )</p>  
                          <label htmlFor="title">Site survey file</label>
                          <input type="file" id="site-survey-sheet" className="form-control" onChange={this.handleFileChange} required /> 
                        </div>
                        <div className="my-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <>
                   <div className="h-100 w-50">
                    <div className="vendor-form p-4 bg-white shadow-sm rounded border-0">
                      <h4 className="mb-3 fw-bold"> Edit Site Survey details </h4>
                      <form onSubmit={this.handleSubmit} autoComplete="off">
                        <fieldset id="site-survey-update-from" className="my-0 mx-auto overflow-y-scroll" style={{height:'75vh'}}>
                          <div className="row g-0">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="primary_no">Order / Primary No.</label>
                              <input type="text" name="primary_no" className="form-control shadow-none bg-light" value={this.state.primary_no} readOnly />
                            </div>  
                             <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="latitude">Latitude</label>
                              <input type="text" name="latitude" className="form-control shadow-none" maxLength="50" value={this.state.latitude} onChange={this.handleInputChange} />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="longitude">Longitude</label>
                              <input type="text" name="longitude" className="form-control shadow-none" maxLength="50" value={this.state.longitude} onChange={this.handleInputChange} />
                            </div> 
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="water_source">Water Source</label>
                              <input type="text" name="water_source" className="form-control shadow-none" maxLength="50" value={this.state.water_source} onChange={this.handleInputChange} required />
                            </div> 
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="water_available_depth">Water Available Depth</label>
                              <input type="text" name="water_available_depth" className="form-control shadow-none" maxLength="50" value={this.state.water_available_depth} onChange={this.handleInputChange} required />
                            </div> 
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="required_head">Required Head</label>
                              <input type="text" name="required_head" className="form-control shadow-none" maxLength="50" value={this.state.required_head} onChange={this.handleInputChange} required />
                            </div> 
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="survey_pump_type">Survey Pump Type</label>
                              <input type="text" name="survey_pump_type" className="form-control shadow-none" maxLength="50" value={this.state.survey_pump_type} onChange={this.handleInputChange} required />
                            </div> 
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="survey_pump_capacity">Survey Pump Capacity</label>
                              <input type="text" name="survey_pump_capacity" className="form-control shadow-none" maxLength="50" value={this.state.survey_pump_capacity} onChange={this.handleInputChange} required />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="status">Status</label>
                              <select name="status" className="form-select shadow-none" value={this.state.status} onChange={this.handleInputChange} required>
                                <option value={0}>Declined</option>
                                <option value={1}>Approved</option>
                              </select>
                            </div>
                          </div>                        
                          <div className="py-2">
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const SiteSurveyList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const saralNoRefMatches = item.primary_no && item.primary_no.toLowerCase().includes(filterText.toLowerCase());  
    return saralNoRefMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_SiteSurvey_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">        
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none w-25" 
           placeholder="Search by Order's Primary No." 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
      {
          name: 'Primary No.',
          selector: row => row.primary_no,
          sortable: true,
      },
      {
          name: 'Latitude',
          selector: row => row.latitude,
          sortable: true,
      },
      {
          name: 'Longitude',
          selector: row => row.longitude,
          sortable: true,
      },
      {
          name: 'Water Source',
          selector: row => row.water_source,
          sortable: true,
      },
      {
          name: 'Water Aval. Depth',
          selector: row => row.water_available_depth,
          sortable: true,
      },
      {
          name: 'Required Head',
          selector: row => row.required_head,
          sortable: true,
      },
      {
          name: 'Survey Pump Type',
          selector: row => row.survey_pump_type,
          sortable: true,
      },
      {
          name: 'Survey Pump Capacity',
          selector: row => row.survey_pump_capacity,
          sortable: true,
      },
      {
          name: 'Status',
          selector: row => row.status,
          sortable: true,
      },
      {
          name: 'ACTION',
          selector: row => row.edit
      }      
  ]; 

  const data = filteredItems.map((item, index)=>{
    return {
      id                   : item.order_id,
      primary_no           : item.primary_no,
      latitude             : item.latitude,
      longitude            : item.longitude,
      water_source         : item.water_source,
      water_available_depth: item.water_available_depth,
      required_head        : item.required_head,
      survey_pump_type     : item.survey_pump_type,
      survey_pump_capacity : item.survey_pump_capacity,
      status : <span className={item.status?"text-primary":"text-danger"}>{item.status?"Completed":"Pending"}</span>,
      edit   : <FontAwesomeIcon icon={faPenToSquare} color="blue" onClick={()=> props.edit(item.order_id)} />
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Site Survey</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete the site surveys of:\r ${selectedRows.map(r => r.application_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"             
            pointerOnHover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}              
          />
        </div>
      </div>
    </>
  );  
}


export { SiteSurvey };
